import { getExploreName } from '../utils/platform';

export const devConfig = {
  sdkKey: 'hCTczWKE0Hs5oT6nTJ3cRGuYYHhVXdss96Q5',
  sdkSecret: 'nOaLDnC0hJocuXmONlhhTzpLAtJ8WRrYpGBR',
  topic: 'SomeTopic',
  name: `${getExploreName()}-${Math.floor(Math.random() * 1000)}`,
  password: '123456',
  signature: '',
};
